<template>
  <div>
    <b-overlay :show="show" rounded="sm">
      <b-card title="Venue" no-body>
        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">
            <!-- sorting  -->

            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mr-1 mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filter"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              @click="set_filter('')"
            >
              All
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="set_filter('online')"
            >
              online({{ online }})
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              @click="set_filter('offline')"
            >
              Offline({{ offline }})
            </b-button>
          </div>
          <br />
          <code> จำนวนทั้งหมด {{ totalRows }} ตัว</code>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(status)="data">
            <template>
              <div v-if="data.item.status === 'offline'">
                <b-badge variant="danger" class="badge-glow"> offline </b-badge>
              </div>
              <div v-else>
                <b-badge variant="success" class="badge-glow"> online </b-badge>
              </div>
            </template>
          </template>

          <template #cell(status_install)="data">
            <b-form-select
              :value="data.item.status_install"
              :options="options"
              :id="data.item.id"
              @change="onstatus_install(data.item.id, data.item.mac,data.item.status_install)"
            />
            <!-- @click="update_status_install(data.item.id)" -->
          </template>
          <template #cell(description)="data">
            <div style="width: 100px">
              {{ data.item.description }}
            </div>
          </template>
          <template #cell(address)="data">
            <div style="width: 100px">
              {{ data.item.address }}
            </div>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BMedia,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BOverlay,
} from "bootstrap-vue";
import api from "@/api";
import addfes from "./Add_fes.vue";
import editfes from "./edit_fes.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
    BDropdown,
    BDropdownItem,
    addfes,
    editfes,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: "เลือกสถานะ" },
        { value: "รอติดตั้ง", text: "รอติดตั้ง" },
        { value: "ติดตั้งแล้ว", text: "ติดตั้งแล้ว" },
      ],
      show: true,
      isAddCampaign: false,
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "number",
          label: "ลำดับ",
          sortable: true,
        },
        {
          key: "mac",
          label: "mac",
        },
        {
          key: "description",
          label: "description",
        },
        {
          key: "type",
          label: "Gateway",
          sortable: true,
        },
        {
          key: "address",
          label: "address",
        },

        {
          key: "lastseen",
          label: "lastseen",
        },
        {
          key: "framedipaddress",
          label: "IP",
        },
        {
          key: "acctstoptime",
          label: "Acctstoptime VPN",
        },
        {
          key: "status",
          label: "status",
          sortable: true,
        },
        { key: "status_install", label: "status install", sortable: true },
        // { key: "lastupdate", label: "lastupdate", sortable: true},
      ],
      /* eslint-disable global-require */
      items: [],
      offline: 0,
      online: 0,
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.loaddata();
    // this.totalRows = this.items.length
  },
  methods: {
    onstatus_install(id, mac,data_old) {
      console.log("id :>> ", id);
      console.log("data_old :>> ", data_old);
      var data = document.getElementById(id).value;
      console.log("data :>> ", data);
      const params = {
        id: id,
        status_install: data,
      };
      console.log("params :>> ", params);
      this.$swal({
        title: "คุณต้องการแก้ไขข้อมูล?",
        text: "ต้องการอัพเดตสถานะเป็น" + data,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
       


        if (result.value) {
          api.post("/update_hotspot_status_install", params).then((response) => {
          console.log("response", response.data);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "อัพเดตสถานะสำเร็จ",
              icon: "BellIcon",
              text: "👋 อัพเดตสถานะ " + mac + "  เป็น " + data + "   สำเร็จ!!",
            },
          });
          this.loaddata();
        });
        } else if (result.dismiss === 'cancel') {
          document.getElementById(id).value = data_old
        }


      });
    },
    set_filter(filter) {
      this.filter = filter;
    },
    async loaddata() {
      const params = {
        campaign_type: "date",
      };

      api
        .post("/hotspotlistGateway", params)
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.items = response.data.dataGateway;
          this.totalRows = response.data.totalGateway;
          this.totalRows = response.data.totalGateway;
          this.offline = response.data.categories.offline.length;
          this.online = response.data.categories.online.length;
          // console.log(response.data);
          this.show = false;
          // const groupByCategory =  this.items.groupBy((status) => {
          //   return  this.items.status;
          // });
          // console.log(groupByCategory);
        })
        .catch((error) => {
          // router.push('/pages/not-authorized');
        });
    },
    delete_data(id) {
      console.log("delete", id);
      const params = {
        id: id,
      };
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          api.post("/festivaldel", params).then((response) => {
            console.log(response.data);
            this.loaddata();
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Your file has been deleted.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
    edit_data(id) {
      this.$refs.modal.showModaledit(id);
    },
    show_data(id) {
      this.$refs.modalshow.showModaldata(id);
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
