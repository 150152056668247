<template>
  <div>
    <!-- modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      title="Edit data"
      ok-only
      ok-title="Exit"
    >
      <b-card-text>
        <b-row class="invoice-add">
          <b-col cols="12" xl="12" md="12">
            <b-form enctype="multipart/form-data" @submit.prevent>
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding form-item-section">
                  <div ref="form" class="repeater-form">
                    <b-row ref="row" class="pb-2">
                      <b-form @submit.prevent>
                        <b-row>
                          <b-col cols="6">
                            <b-form-group label="Festival">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="festival"
                                  v-model="Efestival"
                                  type="text"
                                  placeholder="Festival"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.festival == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>

                          <b-col cols="6">
                            <b-form-group label="Region">
                              <b-input-group class="input-group-merge">
                                <b-form-select
                                  id="region"
                                  placeholder="region"
                                  v-model="region"
                                  :options="options"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.region == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="Date Start">
                              <b-input-group class="input-group-merge">
                                <flat-pickr
                                  id="datestart"
                                  v-model="Edatestart"
                                  placeholder=" Date Start"
                                  class="form-control"
                                  :config="{
                                    enableTime: true,
                                    dateFormat: 'Y-m-d H:i',
                                  }"
                                />
                              </b-input-group>

                              <b-alert
                                v-if="check.datestart == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="Date End">
                              <b-input-group class="input-group-merge">
                                <flat-pickr
                                  id="dateend"
                                  v-model="Edateend"
                                  placeholder=" Date End"
                                  class="form-control"
                                  :config="{
                                    enableTime: true,
                                    dateFormat: 'Y-m-d H:i',
                                  }"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.dateend == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                          <br /><br />
                          <b-col cols="6">
                            <div v-if="Eimg_background == ''">
                              <b-form-group
                                label="Background"
                                label-for="background"
                                label-cols-md="4"
                              >
                                <b-form-file
                                  placeholder="อัพโหลดรูป"
                                  id="background"
                                  ref="background"
                                  type="file"
                                  accept=".jpeg,.png,.jpg,GIF"
                                  :hidden="true"
                                  @change="onFileChangebackground2"
                                /><b-alert
                                  v-if="check.dateend == true"
                                  variant="danger"
                                  show
                                >
                                  <span>Please Enter</span>
                                </b-alert>
                              </b-form-group>
                            </div>
                            <div v-else>
                               <b-button
                                    v-if="Eimg_background"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    size="sm"
                                    variant="relief-danger"
                                    @click="del_img_background()"
                                  >
                                    delete
                                  </b-button>
                              <b-img thumbnail fluid :src="Eimg_background" />
                              <b-form-group
                                label="Background"
                                label-for="background"
                                label-cols-md="4"
                              >
                                <b-form-file
                                  placeholder="เปลี่ยนรูปภาพ"
                                  id="background"
                                  ref="background"
                                  type="file"
                                  accept=".jpeg,.png,.jpg,GIF"
                                  :hidden="true"
                                  @change="onFileChangebackground2"
                                /><b-alert
                                  v-if="check.Filebackground == true"
                                  variant="danger"
                                  show
                                >
                                  <span>Please Enter</span>
                                </b-alert>
                              </b-form-group>
                            </div>
                          </b-col>
                          <b-col cols="6">
                            <div v-if="Eimg_logo == ''">
                              <b-form-group
                                label="Logo"
                                label-for="logo"
                                label-cols-md="4"
                              >
                                <b-form-file
                                  placeholder="อัพโหลดรูป"
                                  id="logo"
                                  ref="logo"
                                  type="file"
                                  accept=".jpeg,.png,.jpg,GIF"
                                  :hidden="true"
                                  @change="onFileChangelogo2"
                                /><b-alert
                                  v-if="check.Filelogo == true"
                                  variant="danger"
                                  show
                                >
                                  <span>Please Enter</span>
                                </b-alert>
                              </b-form-group>
                            </div>
                            <div v-else>
                               <b-button
                                    v-if="Eimg_logo"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    size="sm"
                                    variant="relief-danger"
                                    @click="del_img_logo()"
                                  >
                                    delete
                                  </b-button>
                              <b-img thumbnail fluid :src="Eimg_logo" />
                              <b-form-group
                                label="Logo"
                                label-for="logo"
                                label-cols-md="4"
                              >
                                <b-form-file
                                  placeholder="เปลียนรูปภาพ"
                                  id="logo"
                                  ref="logo"
                                  type="file"
                                  accept=".jpeg,.png,.jpg,GIF"
                                  :hidden="true"
                                  @change="onFileChangelogo2"
                                /><b-alert
                                  v-if="check.Filelogo == true"
                                  variant="danger"
                                  show
                                >
                                  <span>Please Enter</span>
                                </b-alert>
                              </b-form-group>
                            </div>
                          </b-col>

                          <b-col cols="12">
                            <div v-if="Eimg_center == ''">
                              <b-form-group
                                label="logo center"
                                label-for="logo_center"
                                label-cols-md="4"
                              >
                                <b-form-file
                                  placeholder="อัพโหลดรูป"
                                  id="logo_center"
                                  ref="logo_center"
                                  type="file"
                                  accept=".jpeg,.png,.jpg,GIF"
                                  :hidden="true"
                                  @change="onFileChangeimgcenter2"
                                />
                              </b-form-group>
                            </div>
                            <div v-else>
                               <b-button
                                    v-if="Eimg_center"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    size="sm"
                                    variant="relief-danger"
                                    @click="del_img_center()"
                                  >
                                    delete
                                  </b-button>
                              <b-img thumbnail fluid :src="Eimg_center" />
                              <b-form-group
                                label="logo center"
                                label-for="logo_center"
                                label-cols-md="4"
                              >
                                <b-form-file
                                  placeholder="เปลียนรูปภาพ"
                                  id="logo_center"
                                  ref="logo_center"
                                  type="file"
                                  accept=".jpeg,.png,.jpg,GIF"
                                  :hidden="true"
                                  @change="onFileChangeimgcenter2"
                                />
                              </b-form-group>
                            </div>
                          </b-col>
                          <!-- submit and reset -->
                          <b-col>
                            <br /><br />
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="submit"
                              variant="primary"
                              class="mr-1"
                              @click="update()"
                            >
                              Update
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-row>
                  </div>
                </b-card-body>
              </b-card>
            </b-form>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  BFormFile,
  BImg,
  BMedia,
  VBModal,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BFormText,
  BAlert,
    BFormSelect,
} from "bootstrap-vue";
import Swal from "sweetalert2";
import Ripple from "vue-ripple-directive";
import "animate.css";
import api from "@/api";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    BFormFile,
    BListGroupItem,
    BCardTitle,
    BImg,
    BMedia,
    VBModal,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BFormText,
    BAlert,
    quillEditor,
    vSelect,
      BFormSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      dialog: null,
      options: [
        { value: null, text: "เลือกภูมิภาค" },
        { value: "ทั้งหมด", text: "ทั้งหมด" },
        { value: "ภาคกลาง", text: "ภาคกลาง" },
        { value: "ภาคอีสาน", text: "ภาคอีสาน" },
        { value: "ภาคเหนือ", text: "ภาคเหนือ" },
        { value: "ภาคใต้", text: "ภาคใต้" },
        { value: "ภาคตะวันออก", text: "ภาคตะวันออก" },
        { value: "ภาคตะวันตก", text: "ภาคตะวันตก" },
      ],
      Eid: null,
      Efestival: null,
      Edatestart: null,
      Edateend: null,
      EFilelogo: null,
      EFilebackground: null,
      EFilelogocenter: null,
      Eimg_center: null,
      Eimg_logo: null,
      Eimg_background: null,
      region: null,
      check: {
        Efestival: false,
        Edatestart: false,
        Edateend: false,
        EFilelogo: false,
        EEdateend: false,
        region: false,
      },
    };
  },
  methods: {
    update() {
      console.log("update");
      if (Date.parse(this.Edatestart) > Date.parse(this.Edateend)) {
        console.log(this.Edateend);
        this.Edateend = null;
        // error date
        this.check.Edateend = true;

        this.$swal({
          title: "Error!",
          text: " End date should be greater than Start date",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        if (this.Efestival && this.Edatestart && this.Edateend) {
          Swal.fire({
            title: "ยืนยันการบันทึกข้อมูล",
            text: "",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            showLoaderOnConfirm: true,
            preConfirm: () => {},
          }).then((result) => {
            let formData = new FormData();
            formData.append("id", this.Eid);
            formData.append("Filelogo", this.EFilelogo);
            formData.append("Filebackground", this.EFilebackground);
            formData.append("Filelogocenter", this.EFilelogocenter);
            formData.append("festival", this.Efestival);
            formData.append("datestart", this.Edatestart);
            formData.append("dateend", this.Edateend);
            formData.append("img_logo", this.Eimg_logo);
            formData.append("img_background", this.Eimg_background);
            formData.append("img_center", this.Eimg_center);
            formData.append("region", this.region);
            api.post("/festivalupdat", formData).then((response) => {
              console.log("response", response.data);
              if (response) {
                Swal.fire({
                  icon: "success",
                  title: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.dialog = false;
                this.refresh();
              }
            });
          });
        } else {
          if (!this.Efestival) {
            this.check.Efestival = true;
            console.log("Efestival null");
          }
          if (!this.Edatestart) {
            this.check.Edatestart = true;
            console.log("Edatestart null");
          }
          if (!this.Edateend) {
            this.check.Edateend = true;
            console.log("Edateend null");
          }
          if (!this.region) {
            this.check.region = true;
            console.log("region null");
          }
        }
      }
    },
    del_img_logo() {
      this.Eimg_logo = "";
      this.EFilelogo = "";
      // console.log("set img_logo = null", this.Eimg_logo);
    },
    del_img_background() {
      this.Eimg_background = "";
      this.EFilebackground = "";
      // console.log("set img_background = null", this.Eimg_background);
    },
    del_img_center() {
      this.Eimg_center = "";
      this.EFilelogocenter = "";
      // console.log("set img_center = null", this.Eimg_center);
    },

    async showModaledit(id) {
      this.reset();
      console.log("id", id);
      this.Eid = id;
      const params = {
        id: id,
      };
      // get date axios
      api.post("/festivalgetid", params).then((response) => {
        console.log(response.data);
        for (let item of response.data.data) {
          (this.Efestival = item.title),
            (this.Edatestart = item.datestart),
            (this.Edateend = item.dateend),
            (this.Eimg_logo = item.img_logo),
            (this.Eimg_background = item.img_background);
          this.Eimg_center = item.img_center;
          this.region = item.region;
        }
      });
      this.dialog = true;
    },

    refresh() {
      this.$emit("loaddata", "loaddata");
    },
    onFileChangebackground2(e) {
      this.EFilebackground = e.target.files[0];
      console.log("EFilebackground แก้ไข", this.EFilebackground);
      const image = e.target.files[0];
      this.Eimg_background = URL.createObjectURL(image); ///show
    },
    onFileChangelogo2(e) {
      this.EFilelogo = e.target.files[0];
      console.log("EFilelogo แก้ไข", this.EFilelogo);
      const image = e.target.files[0];
      this.Eimg_logo = URL.createObjectURL(image); ///show
    },
    onFileChangeimgcenter2(e) {
      this.EFilelogocenter = e.target.files[0];
      console.log("EFilelogocenter เพิ่ม", this.EFilelogocenter);
      const image = e.target.files[0];
      this.Eimg_center = URL.createObjectURL(image); ///show
    },
    reset() {
      this.Edatestart = null;
      this.Edateend = null;
      this.Efestival = null;
      this.Eimg_logo = null;
      this.Eimg_background = null;
      this.EFilelogo = null;
      this.EFilebackground = null;
      this.check.festival = false;
      this.check.dateend = false;
      this.check.datestart = false;
      this.check.Filelogo = false;
      this.check.Filebackground = false;
      this.check.img_logo = false;
      this.check.img_background = false;
      this.check.region = false;
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>