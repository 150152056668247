<template>
  <section class="invoice-add-wrapper">
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-xl
      variant="outline-primary"
    >
      Add Festival
    </b-button>

    <b-modal
      v-model="dialog1"
      id="modal-xl"
      ok-only
      ok-title="close"
      centered
      size="lg"
      title="Add Festival"
    >
      <b-card-text>
        <b-row class="invoice-add">
          <b-col cols="12" xl="12" md="12">
            <b-form enctype="multipart/form-data" @submit.prevent>
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding form-item-section">
                  <div ref="form" class="repeater-form">
                    <b-row ref="row" class="pb-2">
                      <b-form @submit.prevent>
                        <b-row>
                          <b-col cols="6">
                            <b-form-group label="Festival">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="festival"
                                  v-model="festival"
                                  type="text"
                                  placeholder="Festival"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.festival == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>

                          <b-col cols="6">
                            <b-form-group label="Region">
                              <b-input-group class="input-group-merge">
                                <b-form-select
                                  id="region"
                                  placeholder="region"
                                  v-model="region"
                                  :options="options"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.region == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="Date Start">
                              <b-input-group class="input-group-merge">
                                <flat-pickr
                                  id="datestart"
                                  v-model="datestart"
                                  placeholder=" Date Start"
                                  class="form-control"
                                  :config="{
                                    enableTime: true,
                                    dateFormat: 'Y-m-d H:i',
                                  }"
                                />
                              </b-input-group>

                              <b-alert
                                v-if="check.datestart == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="Date End">
                              <b-input-group class="input-group-merge">
                                <flat-pickr
                                  id="dateend"
                                  v-model="dateend"
                                  placeholder=" Date End"
                                  class="form-control"
                                  :config="{
                                    enableTime: true,
                                    dateFormat: 'Y-m-d H:i',
                                  }"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.dateend == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                          <br /><br />
                          <b-col cols="6">
                            <div v-if="img_background == ''">
                              <b-form-group
                                label="Background"
                                label-for="background"
                                label-cols-md="4"
                              >
                                <b-form-file
                                  placeholder="อัพโหลดรูป"
                                  id="background"
                                  ref="background"
                                  type="file"
                                  accept=".jpeg,.png,.jpg,GIF"
                                  :hidden="true"
                                  @change="onFileChangebackground"
                                /><b-alert
                                  v-if="check.dateend == true"
                                  variant="danger"
                                  show
                                >
                                  <span>Please Enter</span>
                                </b-alert>
                              </b-form-group>
                            </div>
                            <div v-else>
                              <b-img thumbnail fluid :src="img_background" />
                              <b-form-group
                                label="Background"
                                label-for="background"
                                label-cols-md="4"
                              >
                                <b-form-file
                                  placeholder="เปลี่ยนรูปภาพ"
                                  id="background"
                                  ref="background"
                                  type="file"
                                  accept=".jpeg,.png,.jpg,GIF"
                                  :hidden="true"
                                  @change="onFileChangebackground"
                                /><b-alert
                                  v-if="check.Filebackground == true"
                                  variant="danger"
                                  show
                                >
                                  <span>Please Enter</span>
                                </b-alert>
                              </b-form-group>
                            </div>
                          </b-col>
                          <b-col cols="6">
                            <div v-if="img_logo == ''">
                              <b-form-group
                                label="Logo"
                                label-for="logo"
                                label-cols-md="4"
                              >
                                <b-form-file
                                  placeholder="อัพโหลดรูป"
                                  id="logo"
                                  ref="logo"
                                  type="file"
                                  accept=".jpeg,.png,.jpg,GIF"
                                  :hidden="true"
                                  @change="onFileChangelogo"
                                /><b-alert
                                  v-if="check.Filelogo == true"
                                  variant="danger"
                                  show
                                >
                                  <span>Please Enter</span>
                                </b-alert>
                              </b-form-group>
                            </div>
                            <div v-else>
                              <b-img thumbnail fluid :src="img_logo" />
                              <b-form-group
                                label="Logo"
                                label-for="logo"
                                label-cols-md="4"
                              >
                                <b-form-file
                                  placeholder="เปลียนรูปภาพ"
                                  id="logo"
                                  ref="logo"
                                  type="file"
                                  accept=".jpeg,.png,.jpg,GIF"
                                  :hidden="true"
                                  @change="onFileChangelogo"
                                /><b-alert
                                  v-if="check.Filelogo == true"
                                  variant="danger"
                                  show
                                >
                                  <span>Please Enter</span>
                                </b-alert>
                              </b-form-group>
                            </div>
                          </b-col>

                          <b-col cols="12">
                            <div v-if="img_center == ''">
                              <b-form-group
                                label="logo center"
                                label-for="logo_center"
                                label-cols-md="4"
                              >
                                <b-form-file
                                  placeholder="อัพโหลดรูป"
                                  id="logo_center"
                                  ref="logo_center"
                                  type="file"
                                  accept=".jpeg,.png,.jpg,GIF"
                                  :hidden="true"
                                  @change="onFileChangeimgcenter"
                                />
                              </b-form-group>
                            </div>
                            <div v-else>
                              <b-img thumbnail fluid :src="img_center" />
                              <b-form-group
                                label="logo center"
                                label-for="logo_center"
                                label-cols-md="4"
                              >
                                <b-form-file
                                  placeholder="เปลียนรูปภาพ"
                                  id="logo_center"
                                  ref="logo_center"
                                  type="file"
                                  accept=".jpeg,.png,.jpg,GIF"
                                  :hidden="true"
                                  @change="onFileChangeimgcenter"
                                />
                              </b-form-group>
                            </div>
                          </b-col>

                          <!-- submit and reset -->
                          <b-col>
                            <br /><br />
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="submit"
                              variant="primary"
                              class="mr-1"
                              @click="save()"
                            >
                              Submit
                            </b-button>
                            <b-button
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              type="reset"
                              variant="outline-secondary"
                              @click="reset()"
                            >
                              Reset
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-row>
                  </div>
                </b-card-body>
              </b-card>
            </b-form>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  BFormFile,
  BImg,
  BMedia,
  VBModal,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BFormText,
  BAlert,
  BFormSelect,
} from "bootstrap-vue";
import Swal from "sweetalert2";
import Ripple from "vue-ripple-directive";
import "animate.css";
import api from "@/api";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    BFormFile,
    BListGroupItem,
    BCardTitle,
    BImg,
    BMedia,
    VBModal,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BFormText,
    BAlert,
    quillEditor,
    vSelect,
    BFormSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      dialog1: null,
      festival: null,
      datestart: null,
      dateend: null,
      Filelogo: null,
      Filebackground: null,
      Filelogocenter: null,
      img_logo: null,
      img_background: null,
      img_center: null,
      region: null,
      check: {
        festival: false,
        datestart: false,
        dateend: false,
        Filelogo: false,
        Filebackground: false,
        region: false,
      },
      options: [
        { value: null, text: "เลือกภูมิภาค" },
        { value: "ทั้งหมด", text: "ทั้งหมด" },
        { value: "ภาคกลาง", text: "ภาคกลาง" },
        { value: "ภาคอีสาน", text: "ภาคอีสาน" },
        { value: "ภาคเหนือ", text: "ภาคเหนือ" },
        { value: "ภาคใต้", text: "ภาคใต้" },
        { value: "ภาคตะวันออก", text: "ภาคตะวันออก" },
        { value: "ภาคตะวันตก", text: "ภาคตะวันตก" },
      ],
    };
  },
  watch: {},
  mounted() {},
  created() {},
  computed: {},
  methods: {
    onFileChangebackground(e) {
      this.Filebackground = e.target.files[0];
      console.log("Filebackground เพิ่ม", this.Filebackground);
      const image = e.target.files[0];
      this.img_background = URL.createObjectURL(image); ///show
    },
    onFileChangelogo(e) {
      this.Filelogo = e.target.files[0];
      console.log("Filelogo เพิ่ม", this.Filelogo);
      const image = e.target.files[0];
      this.img_logo = URL.createObjectURL(image); ///show
    },
    onFileChangeimgcenter(e) {
      this.Filelogocenter = e.target.files[0];
      console.log("Filelogocenter เพิ่ม", this.Filelogocenter);
      const image = e.target.files[0];
      this.img_center = URL.createObjectURL(image); ///show
    },
    refresh() {
      this.$emit("loaddata", "loaddata");
    },
    save() {
      console.log("save");
      if (Date.parse(this.datestart) > Date.parse(this.dateend)) {
        console.log(this.dateend);
        this.dateend = null;
        // error date
        this.check.dateend = true;

        this.$swal({
          title: "Error!",
          text: " End date should be greater than Start date",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        if (
          this.festival &&
          this.datestart &&
          this.dateend &&
          this.Filelogo &&
          this.Filebackground &&
          this.region
        ) {
          Swal.fire({
            title: "ยืนยันการบันทึกข้อมูล",
            text: "",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            showLoaderOnConfirm: true,
            preConfirm: () => {},
          }).then((result) => {
            let formData = new FormData();
            formData.append("Filelogo", this.Filelogo);
            formData.append("Filebackground", this.Filebackground);
            formData.append("Filelogocenter", this.Filelogocenter);
            formData.append("festival", this.festival);
            formData.append("datestart", this.datestart);
            formData.append("dateend", this.dateend);
            formData.append("region", this.region);
            api.post("/addfestiva", formData).then((response) => {
              console.log("response", response.data);
              if (response) {
                Swal.fire({
                  icon: "success",
                  title: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.dialog1 = false;
                this.refresh();
                this.reset();
              }
            });
          });
        } else {
          if (!this.festival) {
            this.check.festival = true;
            console.log("festival null");
          }
          if (!this.datestart) {
            this.check.datestart = true;
            console.log("datestart null");
          }
          if (!this.dateend) {
            this.check.dateend = true;
            console.log("dateend null");
          }
          if (!this.Filelogo) {
            this.check.Filelogo = true;
            console.log("Filelogo null");
          }
          if (!this.Filebackground) {
            this.check.Filebackground = true;
            console.log("Filebackground null");
          }
          if (!this.region) {
            this.check.region = true;
            console.log("region null");
          }
        }
      }
    },
    reset() {
      this.festival = null;
      this.datestart = null;
      this.dateend = null;
      this.Filelogo = null;
      this.Filebackground = null;
      this.Filelogocenter = null;
      this.img_center = null;
      this.img_logo = null;
      this.img_background = null;
      this.region = null;
      this.collapse = false;
      this.check.festival = false;
      this.check.dateend = false;
      this.check.datestart = false;
      this.check.Filelogo = false;
      this.check.Filebackground = false;
      this.check.img_logo = false;
      this.check.img_background = false;
      this.check.img_center = false;
      this.check.Filelogocenter = false;
      this.check.region = false;
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>